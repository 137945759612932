<template>
  <q-dialog v-bind="$attrs" :full-width="isMaximize">
    <q-card>
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">{{ image.title }}</div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section v-if="image">
        <q-img
          :src="image.url_thumbnail"
          class="viewer-image rounded-borders"
          spinner-color="primary"
          fit="contain"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ImageViewerModal",
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  computed: {
    isMaximize() {
      return this.$q.screen.width < 585;
    },
  },
};
</script>

<style lang="scss" scoped>
.viewer-image {
  height: 500px;
  width: 500px;
}

@media (max-width: 585px) {
  .viewer-image {
    height: 100%;
    width: 100%;
  }
}
</style>
