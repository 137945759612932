<template>
  <div>
    <q-tabs
      v-model="currentTab"
      inline-label
      class="text-grey-7"
      active-color="primary"
      indicator-color="primary"
    >
      <q-tab
        v-for="chart in charts"
        :key="chart.id"
        :name="chart.title"
        :label="chart.label"
      />
    </q-tabs>

    <q-tab-panels v-model="currentTab" animated class="chart-data">
      <q-tab-panel
        v-for="chart in charts"
        :key="chart.id"
        :name="chart.title"
        class="chart-data__wrapper"
      >
        <div v-if="chart.data.length > 0">
          <ChartTabsItem
            :data="chart.data"
            :label="chart.label"
            :title="chart.title"
            :backgroundColor="chart.backgroundColor"
            :borderColor="chart.borderColor"
          />
        </div>
        <div class="chart-data__error" v-else>
          <p>{{ $t("error.noDashboardData") }}</p>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import ChartTabsItem from "./ChartTabsItem.vue";

export default {
  components: { ChartTabsItem },
  props: {
    charts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentTab: "",
    };
  },
  mounted() {
    if (this.charts.length > 0) {
      this.currentTab = this.charts[0].title;
    }
  },
};
</script>

<style lang="scss" scoped>
.chart-data {
  &__wrapper {
    min-height: 284px;
    display: flex;
    justify-content: center;
  }
  &__error {
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
  }
}

.q-tab {
  background: #ebebeb;
  color: #000;
  font-weight: 600;
  height: 50px;
  
}

.chart-tab {
  p {
    font-size: 1.3rem;
    letter-spacing: 2px;
  }
}
</style>