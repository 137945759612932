<template>
  <q-layout view="hHh lpr lff" class="bg-grey-1">
    <!-- Left Drawer -->
    <q-drawer
      v-model="sidebarOpen"
      show-if-above
      :width="260"
      :breakpoint="1024"
      bordered
      class="bg-grey-2"
    >
      <q-list padding v-if="!loading">
        <SideBar />
      </q-list>
      <q-list padding v-else>
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
        <q-skeleton class="q-mx-sm q-my-sm" type="rect" height="50px" rounded />
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page padding class="dashboard">
        <!-- Header -->
        <div class="dashboard-header">
          <q-btn
            v-if="!sidebarOpen"
            class="dashboard-header__drawer"
            flat
            @click="openSidebar"
            round
            dense
            icon="east space_dashboard "
          />
          <router-link to="/my-races">
            <q-btn
              color="primary"
              :label="$t('dashboard.menu.backBtn')"
              icon="arrow_back"
            />
          </router-link>
        </div>
        <div v-if="loading" class="dashboard-loading">
          <q-spinner-cube color="primary" size="2em" />
          <p>
            {{ $t("dashboard.main.loading") }}
          </p>
        </div>
        <!-- Summary/Charts -->
        <div
          v-if="!loading && !currentRace"
          class="races-loading__error text-subtitle1"
        >
          {{ $t("races.loadingDashboardMessage") }}
        </div>
        <div v-else class="dashboard-wrapper">
          <div class="dashboard-summary">
            <div class="dashboard-summary__info">
              <q-skeleton type="rect" height="190px" square v-if="loading" />
              <SummaryStats
                v-if="currentRace && currentRace.name"
                :race="currentRace"
              />
            </div>
            <q-card class="dashboard-summary__sales">
              <q-skeleton type="rect" height="100%" square v-if="loading" />
              <SummaryCardSales
                v-if="currentRace && currentRace.name"
                :race="currentRace"
                :total-orders="totalOrders"
                :total-album-sales-amount="totalAlbumSalesAmount"
                :total-commission-earned="totalCommissionEarned"
                :total-commission-paid="totalCommissionPaid"
              />
            </q-card>
            <q-card class="dashboard-summary__chart">
              <q-skeleton type="rect" height="240px" square v-if="loading" />
              <ChartTabs v-else :charts="charts" />
            </q-card>
          </div>
          <!-- Orders Table -->
          <div class="dashboard-summary__table">
            <q-skeleton type="rect" height="240px" v-if="loading" />
            <OrdersTable
              v-if="currentRace && currentRace.name"
              :race="currentRace"
              :total-commission-earned="totalCommissionEarned"
            />
            <!-- Shares Table -->
            <SharesTable
              v-if="currentRace && currentRace.name"
              :race="currentRace"
            />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ChartTabs from "@/pages/dashboardRaces/dashboardRacesSingle/components/charts/ChartTabs.vue";
import SummaryCardSales from "@/pages/dashboardRaces/dashboardRacesSingle/components/SummaryCardSales.vue";
import SummaryStats from "@/pages/dashboardRaces/dashboardRacesSingle/components/SummaryStats.vue";
import OrdersTable from "@/pages/dashboardRaces/dashboardRacesSingle/components/OrdersTable.vue";
import SharesTable from "@/pages/dashboardRaces/dashboardRacesSingle/components/SharesTable.vue";
import SideBar from "@/pages/dashboardRaces/dashboardRacesSingle/components/SideBar.vue";

export default {
  name: "DashboardRacesSinglePage",
  components: {
    SummaryStats,
    SummaryCardSales,
    ChartTabs,
    OrdersTable,
    SharesTable,
    SideBar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("dashboardRaces", [
      "links",
      "races",
      "raceId",
      "loading",
      "isSidebarOpen",
    ]),
    ...mapGetters("dashboardRaces", ["getCurrentRace"]),
    sidebarOpen: {
      get() {
        return this.isSidebarOpen;
      },
      set(value) {
        this.toggleSidebar(value);
      },
    },
    currentRace() {
      return this.getCurrentRace;
    },
    charts() {
      if (!this.currentRace || !this.currentRace.timeSeriesData) {
        return [];
      }
      return [
        {
          id: 1,
          data: this.currentRace.timeSeriesData.ordersOverTime || [],
          label: this.$t("dashboard.main.totalOrders"),
          title: this.$t("dashboard.main.totalOrders"),
          backgroundColor: "rgba(255, 99, 132, 1)",
          borderColor: "rgba(255, 99, 132, 1)",
        },
        {
          id: 2,
          data: this.currentRace.timeSeriesData.albumViewsOverTime || [],
          label: this.$t("dashboard.main.albumViews"),
          title: this.$t("dashboard.main.albumViews"),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: "rgba(54, 162, 235, 1)",
        },
        {
          id: 3,
          data: this.currentRace.timeSeriesData.salesAmountOverTime || [],
          label: this.$t("dashboard.main.totalAlbumSalesAmount") + " (zł)",
          title: this.$t("dashboard.main.totalAlbumSalesAmount"),
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          borderColor: "rgba(255, 206, 86, 1)",
        },
      ];
    },
    totalOrders() {
      return this.currentRace.orders.length;
    },
    totalAlbumSalesAmount() {
      return this.currentRace.orders.reduce(
        (acc, order) =>
          acc + (order.orderAmount - order.orderAmount * (order.coupon / 100)),
        0
      );
    },
    totalCommissionEarned() {
      return this.currentRace.orders.reduce(
        (acc, order) =>
          acc +
          (order.orderAmount -
            order.orderAmount * (order.coupon / 100) -
            order.authorCommission -
            order.paygateCommission -
            order.runpixieCommission),
        0
      );
    },
    totalCommissionPaid() {
      return this.currentRace.orders.reduce(
        (acc, order) => acc + order.paygateCommission,
        0
      );
    },
  },
  async created() {
    await this.fetchRaceById(this.$route.params.raceId);
  },
  methods: {
    ...mapActions("dashboardRaces", ["fetchRaceById", "toggleSidebar"]),
    openSidebar() {
      this.toggleSidebar(true);
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  margin: 0 auto;
  max-width: 2000px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-header {
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;

    .dashboard-loading {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      gap: 10px;

      > p {
        margin: 0;
      }
    }
  }
}

.dashboard-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 10px;
  width: 100%;
  margin-bottom: 12px;

  &__info {
    margin: 0 auto;
    margin-bottom: 12px;
    min-width: 250px;
    height: 100%;
    flex-basis: 100%;
  }

  &__sales {
    flex-basis: 100%;
  }

  &__chart {
    overflow: hidden;
    width: 100%;
    & div {
      text-transform: capitalize;
      font-weight: 600;
      width: 100%;
      .q-tab__indicator {
        opacity: 1;
        background: #999;
      }
      .q-tab--active .q-tab__indicator {
        opacity: 1;
        background: $primary;
      }
    }
  }
  &__table {
    width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: $breakpoint-sm-min) {
  .dashboard-header {
    justify-content: space-between;
  }

  .dashboard-summary {
    &__chart {
      flex-basis: 100%;
    }

    &__table {
      width: 100%;
    }
  }
}

@media (min-width: $breakpoint-md-min) {
  .dashboard-summary {
    &__sales {
      flex-basis: 33%;
    }
    &__chart {
      flex-basis: 65%;
    }
  }
}
</style>
