<template>
  <q-drawer
    v-model="isMobileDrawerShown"
    show-if-above
    overlay
    behavior="mobile"
    class="bg-grey-7"
  >
    <q-list>
      <div class="drawer__logo-block">
        <img class="logo" :src="require('../assets/runpixie_logo.webp')" />
        <!-- <q-btn color="primary" icon="language" class="language-button"> -->
        <!-- @TODO prepare to lang button -->
        <!-- <q-menu
            v-if="langsList.length"
            fit
            transition-show="flip-right"
            transition-hide="flip-left"
          >
            <q-list style="width: 56px">
              <q-item
                v-for="(lang, index) in langsList"
                :key="index"
                clickable
                @click="setLanguage(lang.name)"
              >
                <q-item-section>
                  <q-avatar size="24px">
                    <img :src="require(`../../assets/${lang.icon}`)" />
                  </q-avatar>
                </q-item-section>
              </q-item>
            </q-list>
          </q-menu> -->
        <!-- </q-btn> -->
      </div>
      <q-item
        v-for="(option, index) in navbarOptions"
        clickable
        v-ripple
        :key="`${index}_${option.id}`"
        @click="handleClick(option)"
      >
        <!-- <q-item-section avatar>
          <q-icon :name="option.icon" color="white" />
        </q-item-section> -->
        <q-item-section>
          <q-item-label class="text-white item-label">{{
            option.link !== "/" ? option.name : $t(`navigation.addPhoto`)
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.status.loggedIn,
      navigationOptions: (state) => state.global.navigationOptions,
    }),
    navbarOptions() {
      return this.navigationOptions.map(option => ({
        ...option,
        name: option.name.length > 1 && option.name[0] === option.name[0].toUpperCase() 
          ? option.name 
          : this.$t(`navigation.${option.name}`),
      }));
    },

    isMobileDrawerShown: {
      get() {
        return this.$store.state.global.isMobileDrawerShown;
      },
      set() {
        let drawerState = this.$store.state.global.isMobileDrawerShown;
        this.$store.dispatch("global/setDrawerState", !drawerState);
      },
    },
  },
  methods: {
    ...mapActions({
      setDrawerState: global.setDrawerState,
    }),
    handleClick(val) {
      if (val.id === 99 || val.id === 100 || val.id === 101) {
        this.$router.push(val.link);
      } else {
        const { url } = val;
        window.open(url, "_blank");
      }
      this.$store.dispatch("global/setDrawerState", !this.isMobileDrawerShown);
    },
  },
};
</script>

<style scoped lang="scss">
.item-label {
  font-weight: 600;
}
</style>
