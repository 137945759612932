<template>
  <div class="q-pa-md">
    <span class="text-label"></span>
    <q-table
      :title="$t('userPanel.tableTitle')"
      :rows="albums"
      :columns="columns"
      row-key="name"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="albumName" :props="props">
            <a :href="props.row.product_album.url">
              <span v-html="props.row.product_album.name"></span>
            </a>
          </q-td>
          <q-td key="gear" :props="props">
              <span v-html="props.row.product_album.name"></span>
          </q-td>
          <q-td key="commission" :props="props">
            {{ `${props.row.commission_value} zł` }}
          </q-td>
          <q-td key="date" :props="props">
            {{ props.row.date }}
          </q-td>
          <q-td key="commission_paidout_date" :props="props">
            {{ props.row.commission_paidout_date ? props.row.commission_paidout_date : "-" }}
          </q-td>
          <q-td key="images" :props="props">
            <q-btn
              round
              color="primary"
              :disable="!props.row.product_album.current_author_images.length"
              flat
              icon="fas fa-eye"
              @click="
                showImages(
                  props.row.product_album.current_author_images,
                  props.row.product_album.name
                )
              "
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
export default {
  name: "WalletAlbumsTable",
  props: {
    albums: {
      required: false,
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        {
          name: "albumName",
          label: this.$t("userPanel.tableColumns.album"),
          field: "product_album.name",
          align: "left",
          sortable: false,
          classes: "bg-grey-2 ellipsis",
          headerClasses: "bg-primary text-white",
        },
        {
          name: "gear",
          label: this.$t("userPanel.tableColumns.gear"),
          field: "product_album.name",
          align: "left",
          sortable: false,
        },
        {
          name: "commission",
          align: "left",
          label: this.$t("userPanel.tableColumns.commission"),
          field: "commission_value",
          sortable: false,
        },
        {
          name: "date",
          label: this.$t("userPanel.tableColumns.date"),
          format: (val) => `${this.formateDate(val)}`,
          align: "left",
          field: "date",
          sortable: false,
        },
        {
          name: "commission_paidout_date",
          label: this.$t("userPanel.tableColumns.commissionData"),
          align: "left",
          field: "commission_paidout_date",
        },
        {
          name: "images",
          label: this.$t("userPanel.tableColumns.images"),
          field: "product_album",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    showImages(img, album) {
      this.$emit("showImageModal", { images: img, album_name: album });
    },
  },
};
</script>

<style lang="scss" scoped></style>
