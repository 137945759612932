import axios from "axios";
import authHeader from "@/services/auth-header";
import apiHeader from "@/services/api-header";
import authHeaderQueue from "@/services/auth-header-queue";
import mockData from '@/api/data.json';

export default {
  userEdit(userData, userId) {
    return axios({
      method: "PUT",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/${userId}`,
      headers: apiHeader(),
      data: userData,
    });
  },
  getCountries() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/wc/v3/data/countries`,
      headers: authHeader(),
    });
  },
  userRegistration(userData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/register`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: userData,
    });
  },
  userLogin(userData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/login`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      data: userData,
    });
  },
  sendResetLink(email) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/reset-password/request`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: { email },
    });
  },
  resetPassword(email, key, newPassword, newPasswordConfirmation) {
    return axios({
      method: 'POST',
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/user/reset-password/reset`,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    data: { email, key, new_password: newPassword, new_password_confirmation: newPasswordConfirmation },
    });
  },
  // add-image select endpoints
  getSelectCities() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/wp/v2/cities`,
      headers: authHeader(),
    });
  },
  getSelectCountries() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/wp/v2/countries`,
      headers: authHeader(),
    });
  },
  getSelectGears() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/gear/all`,
      headers: authHeader(),
    });
  },
  sendPhotoData(data) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_QUEUE_API}/api/images/upload`,
      headers: authHeaderQueue(),
      data: data,
    });
  },
  getDuplicates(userId) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_QUEUE_API}/api/images/duplicates`,
      headers: authHeaderQueue(),
      params: {
        user_id: userId,
      },
    });
  },
  acceptDuplicates(userId) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_QUEUE_API}/api/images/duplicates/accept`,
      headers: authHeaderQueue(),
      data: {
        user_id: userId,
      },
    });
  },
  getAddedPhoto(page) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/author/images`,
      params: {
        page,
      },
      headers: authHeader(),
    });
  },
  editEmail(userId, email) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/wp/v2/users/${userId}`,
      headers: authHeader(),
      data: {
        email: email,
      },
    });
  },
  getNavbarOptions(lang) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/menu/main/${lang}`,
      headers: authHeader(),
    });
  },
  getUserWallet() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/author/wallet`,
      headers: authHeader(),
    });
  },
  withdrawUserPayment(withdrawalAmount, currentAccountBalance) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/author/wallet/withdrawal`,
      headers: authHeader(),
      data: {
        withdrawal_amount: withdrawalAmount,
        current_account_balance: currentAccountBalance,
      },
    });
  },
  getWithdrawRequests() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/author/wallet/requests`,
      headers: authHeader(),
    });
  },
  getLanguage() {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_ROOT_API}/api/v1/lang/current`,
    });
  },
  getToken(userData) {
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_QUEUE_API}/api/sanctum/token`,
      data: userData,
    });
  },
  async getUserRaces(/*userId*/) {
    try {
      /*
      const response = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_ROOT_API}/api/v1/races/${userId}`,
        headers: authHeader(),
      });
      return response.data;  
      */
      return mockData.races;

    } catch (error) {
      console.error("Error fetching user races:", error);
      throw error;
    }
  },
  async getRaceData(raceId) {
    try {
      /*
      const response = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_ROOT_API}/api/v1/races/${raceId}`,
        headers: authHeader(),
      });
      return response.data;  
      */
      return mockData.raceDetails[raceId];
      
    } catch (error) {
      console.error("Error fetching race data:", error);
      throw error;
    }
  }
};
