import api from "@/api/api.js";
import _ from "lodash";
import moment from "moment";
import quasarLangSwitcher from "@/services/translation-service.js";
import SessionService from '@/services/session.service';

export const global = {
  namespaced: true,
  state: {
    isMobileDrawerShown: false,
    addedPhotos: null,
    photosPages: 1,
    navigationOptions: [],
    wallet: 0,
    walletSumOfAllTime: 0,
    registeredCommissions: [],
    withdrawRequests: [],
    withdrawalRequestsSumAmmount: 0,
    lang: 'pl',
    soldedImages: [],
    isLoading: false,
    citiesList: [],
    countriesList: [],
    gearsList: [],
    formData: null,
  },
  getters: {
    getFormData: (state) => state.formData,
  },
  actions: {
    saveFormData({ commit }, formData) {
      commit('setFormData', formData);
      SessionService.storeFormData(formData);
    },
    loadFormData({ commit }) {
      const formData = SessionService.getFormData();
      if (formData) {
        commit('setFormData', formData);
      }
    },
    getPageLang({ dispatch }, i18n) {
      api.getLanguage().then((response) => {
        dispatch('setPageLang', { language: response.data, i18n: i18n });
      });
    },
    setPageLang({ commit }, payload) {
      localStorage.setItem('lang', payload.language);
      payload.i18n.locale = payload.language;
      quasarLangSwitcher(payload.language);
      commit('setLanguage', payload.language);
    },
    setDrawerState({ commit }, payload) {
      commit('setDrawerState', payload);
    },
    getAddedPhotos({ commit }, page) {
      commit('setIsLoading', true);
      api
        .getAddedPhoto(page)
        .then((response) => {
          commit('setAddedPhotos', response.data.data.items);
          commit('setPhotosPages', response.data.data.pages);
        })
        .finally(() => {
          commit('setIsLoading', false);
        });
    },
    getNavbarData({ commit }, lang) {
      try {
        api.getNavbarOptions(lang).then((response) => {
          commit('setNavbarOptions', response.data.data);
        });
      } catch (error) {
        console.error(error);
      }
    },
    getUserWalletData({ commit }) {
      api.getUserWallet().then((response) => {
        commit(
          'setUserWalletData',
          response.data.data?.account_balance && response.data.data.account_balance
        );
        commit(
          'setUserWalletSumOfAllTimeData',
          response.data.data?.account_all_earned_money &&
          response.data.data.account_all_earned_money
        );
        commit(
          'setCommissions',
          response.data.data?.registered_commissions &&
          response.data.data.registered_commissions
        );
      });
    },
    getWithdrawRequestList({ commit }) {
      api.getWithdrawRequests().then((response) => {
        commit(
          'setWithdrawRequestList',
          response.data.data ? response.data.data.withdrawal_requests : []
        );
        commit(
          'setWithdrawRequestSum',
          response.data.data ? response.data.data.withdrawal_requests_sum_ammount : 0
        );
      });
    },
    getCities({ commit }) {
      api.getSelectCities().then((response) => {
        const citiesData = response.data;
        const cities = citiesData.map((el) => ({
          ...el,
          value: el.id,
          label: el.title.rendered,
        }));
        commit('setCitiesList', cities);
      });
    },
    getCountries({ commit }) {
      api.getSelectCountries().then((response) => {
        const countryData = response.data;
        const countries = countryData.map((el) => ({
          ...el,
          value: el.id,
          label: el.title.rendered,
        }));
        commit('setCountriesList', countries);
      });
    },
    getGears({ commit }) {
      return api.getSelectGears().then((response) => {
        const gearsData = response.data.data;
        const gears = gearsData.map((el) => ({
          ...el,
          value: el.id,
          label: el.post_title + ' - ' + moment(el.gear_date).format('yyyy/MM/DD'),
        }));
        commit('setGearsList', gears);
      });
    },
  },
  mutations: {
    setFormData(state, formData) {
      state.formData = formData;
    },
    setLanguage(state, payload) {
      state.lang = payload;
    },
    setDrawerState(state, payload) {
      state.isMobileDrawerShown = payload;
    },
    setIsLoading(state, payload) {
      state.isLoading = payload;
    },
    setAddedPhotos(state, payload) {
      state.addedPhotos = payload;
    },
    setPhotosPages(state, payload) {
      state.photosPages = payload;
    },
    setNavbarOptions(state, payload) {
      let options = payload;
      options.push({
        id: 99,
        name: `addPhoto`,
        bgClass: 'home-page',
        link: '/',
      });
      options.push({
        id: 100,
        name: `walletProfile`,
        link: '/userPanel?wallet=1',
      });
      const user = localStorage.getItem('user');
      const userParsed = JSON.parse(user);
      const allowedRoles = ['organizer', 'sponsor', 'administrator'];

      if (user && allowedRoles.includes(userParsed.role)) {
        options.push({
          id: 101,
          name: 'myRaces',
          link: '/my-races',
        });
      }

      state.navigationOptions = options;
    },
    setUserWalletData(state, payload) {
      state.wallet = payload;
    },
    setUserWalletSumOfAllTimeData(state, payload) {
      state.walletSumOfAllTime = payload;
    },
    setCommissions(state, payload) {
      state.registeredCommissions = payload;
      if (payload.length) {
        let tmp = _.map(payload, 'product_album.current_author_images');
        state.soldedImages = tmp.reduce(
          (res, currentArr) => res.concat(currentArr),
          []
        );
      }
    },
    setWithdrawRequestList(state, payload) {
      state.withdrawRequests = payload;
    },
    setWithdrawRequestSum(state, payload) {
      state.withdrawalRequestsSumAmmount = payload;
    },
    setCountriesList(state, payload) {
      state.countriesList = payload;
    },
    setCitiesList(state, payload) {
      state.citiesList = payload;
    },
    setGearsList(state, payload) {
      state.gearsList = payload;
    },
  },
};
