<template>
  <q-footer reveal elevated>
    <div class="toast" v-if="duplicates.length">
      <ul class="duplicates-list">
        <li class="duplicates-list__item duplicates__item--description">
          <p>{{ $t('duplicates.general') }}</p>
        </li>
        <li
          class="duplicates-list__item"
          v-for="duplicate in duplicates"
          :key="duplicate.id"
        >
          <span class="duplicates-list__item-title text-bold">{{ duplicate.title }}</span>
          <span class="q-pl-md">
            {{ duplicate.duplicates }} / {{ duplicate.all }}
          </span>
        </li>
      </ul>
      <div class="row">
    <q-space />
      <q-btn
        color="primary"
        :label="$t('common.understand')"
        class="q-my-md q-ml-auto"
        @click="acceptDuplicate"
      />
      </div>
    </div>
  </q-footer>
</template>

<script>
import api from '@/api/api';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BaseFooter',
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      runLists: (state) => state.global.gearsList,
    }),
  },
  data() {
    return {
      duplicates: [],
    };
  },
  methods: {
    ...mapActions({
      getGears: 'global/getGears',
    }),
    getRaceName(runId) {
      const run = this.runLists.find((item) => item.id === runId);
      return run?.post_title ?? this.$t('duplicates.unknownRace');
    },
    async fetchDuplicateImages() {
      try {
        if(!this.user) {
          return;
        }

        const { data } = await api.getDuplicates(this.user.id);
        if (!data.duplicateImages) {
          return console.log('No duplicate images');
        }

        this.duplicates = data.duplicateImages.map(
          ({ all, duplicates, raceId }) => {
            return {
              all,
              duplicates,
              title: this.getRaceName(raceId),
            };
          }
        );
      } catch (err) {
        console.error('Error fetching duplicate images:', err);
      }
    },
    async acceptDuplicate() {
      try {
        const response = await api.acceptDuplicates(this.user.id)
        const { success } = response.data;

        if (!success) {
          return console.log('Error accepting duplicate images');
        }

        this.duplicates = [];
      } catch (err) {
        console.error('Error accepting duplicate images:', err);
      }
    },
  },
  async mounted() {
    if (!this.gearsList || this.runLists.length === 0) {
      await this.getGears();
    }

    await this.fetchDuplicateImages();
  },
};
</script>
<style>
  .toast {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    max-width: 30rem;
    padding: 1.25rem;
    background-color: #000;
  }
  .duplicates-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 450px) {
    .toast {
      bottom: 0;
      right: 0;
      left: 0;
      max-width: 100%;
      border-radius: 0;
    }
  }
</style>
