<template>
  <div class="chart-container">
    <Line :data="chartData" :options="mergedOptions" />
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "ChartLine",
  components: { Line },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    mergedOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        ...this.options,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-container {
  min-height: 250px;
}
@media (min-width: $breakpoint-md-min) {
  .chart-container {
    min-height: 300px;
  }
}
</style>