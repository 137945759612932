<template>
  <q-page class="row justify-center items-center column add-photo">
    <ProgressModal :progress="progress" :isProgress="isProgress" />
    <div class="area">
      <ul class="circles">
        <li v-for="i in 10" :key="`star_${i}`"></li>
      </ul>
    </div>
    <div class="q-pa-md add-photo__form">
      <div v-if="!user" class="block-overlay">
        <q-icon name="person" class="block-overlay__bg-icon" />
        <div class="block-overlay__content">
          <span>{{ $t("addPhoto.blockedText") }}</span>
          <q-btn
            icon="login"
            color="primary"
            :label="$t('login.loginButton')"
            class="q-mt-sm"
            @click="goToLogin"
          />
        </div>
      </div>
      <q-stepper
        v-model="step"
        ref="stepper"
        color="primary"
        animated
        keep-alive
      >
        <q-step
          class="q-step-uploader"
          :name="1"
          :title="$t('addPhoto.title')"
          icon="image"
          :done="step > 1"
          style="min-height: 200px"
        >
          <div class="q-mb-md q-mt-md">
            <q-toggle
              v-model="filePickerThumbs"
              :label="$t('common.turnOnThumbs')"
            />
            <small></small>
            <q-tooltip anchor="top middle" self="top middle">
              ({{ $t("common.turnOnThumbsInfo") }})
            </q-tooltip>
          </div>

          <FilePicker
            ref="FilePickerRef"
            :filePickerThumbs="filePickerThumbs"
            v-if="!reload"
            @setImages="setImages"
          />
        </q-step>

        <q-step
          :name="2"
          :title="$t('common.completeData')"
          icon="settings"
          :done="step > 2"
          style="min-height: 200px"
        >
          <InformationForm
            @setCity="(val) => (selectedCity = val)"
            @setCountry="(val) => (selectedCountry = val)"
            @setGear="(val) => (selectedGear = val)"
            @setDate="(val) => (date = val)"
          />
        </q-step>

        <q-step
          :name="3"
          :title="$t('common.summary')"
          icon="add_comment"
          style="min-height: 200px"
        >
          <p>
            <span>{{ $t("common.city") }}:</span>
            <q-chip
              v-if="selectedCity"
              color="primary"
              text-color="white"
              icon="local_grocery_store"
            >
              {{ selectedCity.label }}
            </q-chip>
          </p>
          <p>
            <span>{{ $t("common.country") }}:</span>
            <q-chip
              v-if="selectedCountry"
              color="primary"
              text-color="white"
              icon="my_location"
            >
              {{ selectedCountry.label }}
            </q-chip>
          </p>
          <p>
            <span>{{ $t("common.gear") }}:</span>
            <q-chip
              v-if="selectedGear"
              color="primary"
              text-color="white"
              icon="camera_front"
            >
              {{ selectedGear.label }}
            </q-chip>
          </p>
          <p>
            <span>{{ $t("common.imagesCount") }}:</span>
            <q-chip
              v-if="selectedGear"
              color="primary"
              text-color="white"
              icon="camera_front"
            >
              x{{ selectedImage.length }}
            </q-chip>
          </p>
          <p>
            {{ $t("common.date") }}:<q-chip
              v-if="date"
              color="primary"
              text-color="white"
              icon="event"
              >{{ date }}</q-chip
            >
          </p>
        </q-step>

        <template v-slot:navigation>
          <q-stepper-navigation>
            <q-btn
              @click="handleClick"
              :disable="!selectedImage.length"
              color="primary"
              v-ripple
              :label="step === 3 ? $t('common.submit') : $t('common.next')"
            />
            <q-btn
              v-if="step > 1"
              flat
              v-ripple
              color="primary"
              @click="$refs.stepper.previous()"
              :label="$t('common.return')"
              class="q-ml-sm"
            />
            <p class="add-photo-agreement">
              {{ $t("addPhoto.agreement") }} -
              <a :href="statuteUrl">
                {{ $t("common.checkHere") }}
              </a>
            </p>
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </div>
  </q-page>
</template>

<script>
import FilePicker from "./components/FilePicker.vue";
import api from "@/api/api.js";
import { mapState, mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import InformationForm from "./components/InformationForm.vue";
import ProgressModal from "./components/ProgressModal.vue";

import "natural-compare-lite";

export default {
  name: "AddPhotoPage",
  components: {
    FilePicker,
    ProgressModal,
    InformationForm,
  },
  data() {
    return {
      step: 1,
      uuidValue: "",
      selectedCountry: "",
      selectedCity: "",
      selectedGear: "",
      filePickerThumbs: false,
      selectedImage: [],
      date: "",
      reload: false,
      progress: 0,
      isProgress: false,
      success: true,
      statuteUrl:
        process.env.VUE_APP_BACK_ROOT + this.$t("common.statuteRoute"),
      maxCountTry: 5,
      errorTrying: 0,
      imageCount: 0,
      baseTimeOut: 1000,
      imagesErrors: [],
    };
  },
  computed: {
    ...mapGetters("global", ["getFormData"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions("global", [
      "loadFormData",
      "saveFormData",
      "getGears",
      "getCountries",
      "getCities",
    ]),
    setImages(data) {
      this.selectedImage = [...data];
    },
    generateUniqueId() {
      this.uuidValue = uuidv4();
    },
    clearAllData() {
      window.removeEventListener("beforeunload", () => {});
      this.errorTrying = 0;
      this.uuidValue = this.generateUniqueId();
      this.selectedImage = [];
      this.isProgress = false;
      this.progress = 0;
      this.$refs.FilePickerRef.addFromParent(this.selectedImage);
    },
    goToLogin() {
      this.$router.push("/login");
    },
    splitToChunks(array) {
      const packageImagesCount = 20;
      const count = array.length / packageImagesCount;
      const parts = Math.ceil(count);
      let result = [];
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }

      return result;
    },
    asyncReq(data, imagesPackages, progressPart, countProgress) {
      const vm = this;
      return new Promise((resolve) => {
        api
          .sendPhotoData(data)
          .then((response) => {
            const { uploadedImages } = response.data.data;
            const copy = _.clone(vm.selectedImage);
            const imageWithSuccess = uploadedImages.filter(
              (element) => !element.error
            );
            imageWithSuccess.forEach((image) => {
              const index = copy.findIndex(
                (element) => element.name === image.oldName
              );
              if (index !== -1) {
                copy.splice(index, 1);
              }
            });

            vm.selectedImage = _.clone(copy);
            if (!vm.errorTrying) {
              if (countProgress === 0) {
                vm.progress = vm.progress + progressPart / 2;
              } else {
                vm.progress = vm.progress + progressPart;
              }
            }
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },

    async handleClick() {
      const vm = this;

      if (this.step === 3) {
        if (!this.user) return;
        if (
          this.selectedCity &&
          this.selectedCountry &&
          this.selectedGear &&
          this.selectedImage.length
        ) {
          window.addEventListener("beforeunload", () => {
            return "Don't leave";
          });

          const completeData = new FormData();
          completeData.append("date", this.date);
          completeData.append("upload_id", this.uuidValue);
          completeData.append("city", this.selectedCity.id);
          completeData.append("country", this.selectedCountry.id);
          completeData.append("gear", this.selectedGear.id);
          completeData.append("user_id", this.user.id);

          const formData = {
            date: this.date,
            city: this.selectedCity.id,
            country: this.selectedCountry.id,
            gear: this.selectedGear.id,
          };
          this.saveFormData(formData);

          const copyImages = [...vm.selectedImage];
          vm.imageCount = copyImages.length;
          const imagesPackages = vm.splitToChunks(copyImages);
          const progressPart = 1 / imagesPackages.length;

          vm.imagesErrors = [...vm.selectedImage];
          vm.isProgress = true;
          let countProgress = 0;

          vm.proccessSendingImages(
            completeData,
            imagesPackages,
            progressPart,
            countProgress
          );
        } else {
          if (!this.selectedImage.length) this.step = 1;
          if (!this.selectedCity || !this.selectedCountry || !this.selectedGear)
            this.step = 2;

          this.$q.notify({
            icon: "warning",
            color: "negative",
            message: this.$t("validation.completeAllFields"),
          });
        }
      } else {
        this.$refs.stepper.next();
      }
    },

    async proccessSendingImages(
      completeData,
      imagesPackages,
      progressPart,
      countProgress
    ) {
      const vm = this;
      vm.progress = vm.progress + progressPart / 2;
      for (countProgress; countProgress < imagesPackages.length; ) {
        const p = imagesPackages[countProgress];

        completeData.delete("images[]");
        p.forEach((file) => {
          completeData.append("images[]", file);
        });

        await vm.asyncReq(
          completeData,
          imagesPackages,
          progressPart,
          countProgress
        );

        countProgress++;
      }

      const clone = [...vm.selectedImage];
      if (clone.length > 0 && vm.errorTrying < vm.maxCountTry) {
        vm.errorTrying++;
        const time = vm.baseTimeOut * vm.errorTrying;
        const packages = vm.splitToChunks(clone);
        setTimeout(() => {
          vm.proccessSendingImages(completeData, packages, progressPart, 0);
        }, time);

        return;
      }

      if (vm.errorTrying === vm.maxCountTry && clone.length) {
        vm.errorTrying = 0;
        vm.progress = 0;
        vm.selectedImage = [...clone];
        const packages = vm.splitToChunks(clone);
        vm.$q.notify({
          icon: "error",
          color: "negative",
          message: `${vm.$t("error.catchedError")} ${vm.$t(
            "error.addedImagesInfo"
          )} ${vm.imageCount - vm.selectedImage.length}/${vm.imageCount}`,
          timeout: 0,
          actions: [
            {
              label: vm.$t("error.renewSendImages"),
              color: "white",
              handler: () => {
                vm.proccessSendingImages(
                  completeData,
                  packages,
                  progressPart,
                  0
                );
              },
            },
            {
              label: vm.$t("error.sendLater"),
              color: "white",
              handler: async () => {
                const cloneArray = [...vm.selectedImage];
                vm.$refs.FilePickerRef.addFromParent(cloneArray);

                vm.step = 1;
                vm.isProgress = false;
                vm.progress = 0;
                vm.errorTrying = 0;
              },
            },
          ],
        });
        return;
      }
      vm.progress = 1;
      setTimeout(() => {
        this.$q.notify({
          icon: "done",
          color: "positive",
          message: this.$t("success.imageAdded"),
          timeout: 0,
          actions: [{ icon: "close", color: "white" }],
        });
        this.clearAllData();
        this.step = 1;
      }, 2000);
    },

    showPrefillFormMessage() {
      this.$q.notify({
        message: this.$t("dashboard.menu.prefillForm"),
        color: "positive",
        icon: "info",
        position: "bottom",
        timeout: 8000,
      });
    },
  },
  mounted() {
    this.loadFormData();
    if (this.getFormData !== null) {
      this.showPrefillFormMessage();
    }
  },
  created() {
    this.getCities();
    this.getCountries();
    this.getGears();
    this.generateUniqueId();
  },
};
</script>

<style lang="scss" scoped>
.block-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.45);
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  &__bg-icon {
    position: absolute;
    color: rgba(#fff, 0.2);
    z-index: 4;
    font-size: 250px;
  }
  span {
    color: white;
    font-size: 24px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 6;
    justify-content: center;
  }
}
.date-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-photo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  &__button {
    width: 160px;
  }

  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: inherit;
    z-index: 3;
    position: relative;
  }
}
::v-deep {
  .q-uploader__header-content {
    > div {
      a {
        &:nth-of-type(3) {
          display: none;
        }
      }
    }
  }

  .q-stepper {
    -webkit-box-shadow: 0px 0px 10px 0px #ffffff;
    box-shadow: 0px 0px 10px 0px #ffffff;
    @media (max-width: 548px) {
      min-width: 90%;
      &__label {
        display: none;
      }
      .q-stepper__dot:before {
        display: none;
      }
    }
    @media (max-width: 380px) {
      min-width: 100%;
      width: 100%;
    }

    .add-photo-agreement {
      margin-top: 1rem;
    }
  }
  .q-step-uploader {
    .q-stepper {
      &__step {
        &-inner {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
          .q-uploader {
            max-width: 100%;
            box-shadow: none;
            @media (min-width: 548px) {
              &__list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 24px;
              }
              &__file {
                margin: 6px;
                &--img {
                  height: 100px;
                  width: 100px;
                }
              }
              &__header {
                &-content {
                  padding: 32px;
                }
              }
            }
            &__header {
              &-content {
                padding: 18px;
              }
            }
          }
        } //-inner
      } //__step
    } //q-stepper
  }
}
.area {
  background: $secondary;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 0;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
@media (max-width: 450px) {
  .add-photo {
    &__title {
      font-size: 22px;
      padding: 8px;
      margin: 8px 12px;
      top: 10px;
      width: 90%;
    }
  }
  .block-overlay {
    span {
      font-size: 18px;
    }
  }
}
</style>
