<template>
  <q-page class="window-width row justify-center items-center login-page">
    <div class="column">
      <div class="row">
        <q-card square class="login-page__card">
          <q-card-section class="bg-primary">
            <h4 class="text-h5 text-white q-my-md">
              {{ register ? $t("login.register") : $t("login.loginTitle") }}
            </h4>
          </q-card-section>
          <q-fab
            v-model="register"
            color="primary"
            icon="add"
            class="absolute login-page__switch"
          >
            <template #tooltip>
              <q-tooltip> {{ $t("login.registration") }} </q-tooltip>
            </template>
          </q-fab>
          <LoginForm v-if="!register" @showCreateAccount="switchTypeForm" />
          <RegisterForm v-else />
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import LoginForm from "./components/LoginForm.vue";
import RegisterForm from "./components/RegisterForm.vue";
import { mapState } from "vuex";
export default {
  name: "LoginPage",
  components: {
    LoginForm,
    RegisterForm,
  },
  data() {
    return {
      register: false,
    };
  },
  computed: {
    ...mapState({ loggedIn: (state) => state.auth.status.loggedIn }),
  },
  methods: {
    switchTypeForm() {
      this.register = !this.register;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  width: 360px;
}
.login-page {
  background: linear-gradient(lighten($primary, 12%), $primary);
  height: calc(100vh - 50px);
  &__switch {
    top: 96px;
    right: 12px;
    transform: translateY(-50%);
    &.rotate {
      transform: translateY(-50%) rotate(90deg);
    }
  }
  &__card {
    width: 400px;
    // height: 690px;
  }
}
@media (max-width: 450px) {
  .login-page {
    &__card {
      width: 100vw;
      height: 100vh;
    }
  }
}
</style>
