export default function authHeader() {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.jwt_token) {
    return {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: `${user.token_type} ${user.jwt_token}`,
    };
  } else {
    return {};
  }
}
