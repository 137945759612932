<template>
  <SummaryCard :title="$t('dashboard.main.salesReport')">
    <template #content>
      <div class="stats-container">
        <SummaryCardSalesItem
          v-for="item in statsItems"
          :key="item.id"
          :icon="item.icon"
          :label="item.label"
          :value="item.value"
        />
        <div class="stat-item">
        <q-btn @click="goToWallet" class="stats-button" color="primary">{{
          $t("dashboard.main.withdraw")
        }}</q-btn>

        </div>
      </div>
    </template>
  </SummaryCard>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SummaryCard from "./SummaryCard.vue";
import SummaryCardSalesItem from "./SummaryCardSalesItem.vue";

export default {
  name: "SummaryCardSales",
  components: {
    SummaryCard,
    SummaryCardSalesItem,
  },
  props: {
    race: {
      type: Object,
      required: true,
    },
    totalOrders: {
      type: Number,
      required: true,
    },
    totalAlbumSalesAmount: {
      type: Number,
      required: true,
    },
    totalCommissionEarned: {
      type: Number,
      required: true,
    },
    totalCommissionPaid: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      wallet: (state) => state.global.wallet,
      walletSumOfAllTime: (state) => state.global.walletSumOfAllTime,
      navigationOptions: (state) => state.global.navigationOptions,
    }),
    statsItems() {
      return [
        {
          id: 1,
          icon: "fas fa-shopping-cart",
          label: this.$t("dashboard.main.totalOrders"),
          value: this.race.totalOrders,
        },
        {
          id: 2,
          icon: "fas fa-money-bill-wave",
          label: this.$t("dashboard.main.totalAlbumSalesAmount"),
          value: `${this.race.totalAlbumSalesAmount}zł`,
        },
        {
          id: 3,
          icon: "fas fa-chart-line",
          label: this.$t("dashboard.main.totalCommissionEarned"),
          value: `${this.walletSumOfAllTime}zł`,
        },
        {
          id: 4,
          icon: "fas fa-chart-bar",
          label: this.$t("dashboard.main.totalCommissionPaid"),
          value: `${this.walletSumOfAllTime - this.wallet}zł`,
        },
        {
          id: 5,
          icon: "fas fa-clock",
          label: this.$t("dashboard.main.amountToBePaid"),
          value: `${this.wallet}zł`,
        },
      ];
    },
  },
  created() {
    this.getUserWalletData();
  },
  methods: {
    ...mapActions({
      getUserWalletData: "global/getUserWalletData",
    }),
    goToWallet() {
      this.$router.push("/userPanel?wallet=1");
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-container {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 0 12px;
  grid-template-columns: 1fr 1fr;
  min-height: 300px;
}

.stats-button {
  transition: all 0.3s;
  &:hover {
    box-shadow: -1px 9px 11px -10px rgba(0, 0, 0, 0.55);
    -webkit-box-shadow: -1px 9px 11px -10px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: -1px 9px 11px -10px rgba(0, 0, 0, 0.55);
  }
}


</style>