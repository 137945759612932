import moment from 'moment';

class SessionService {
  storeFormData(formData) {
    const sessionObject = {
      expiresAt: moment().add(2, "days"),
      formData,
    };
    sessionStorage.setItem('formData', JSON.stringify(sessionObject));
  }

  getFormData() {
    const sessionData = JSON.parse(sessionStorage.getItem('formData'));
    if (sessionData && new Date(sessionData.expiresAt) > new Date()) {
      return sessionData.formData;
    }
    return null;
  }

  handleResetQuery(key, email) {
    if (key && email) {
      sessionStorage.setItem('resetKey', key);
      sessionStorage.setItem('resetEmail', email);
      return true;
    }
    return false;
  }

  hasResetParams() {
    const storedKey = sessionStorage.getItem('resetKey');
    const storedEmail = sessionStorage.getItem('resetEmail');
    return storedKey && storedEmail;
  }

  getResetKey() {
    return sessionStorage.getItem('resetKey');
  }

  getResetEmail() {
    return sessionStorage.getItem('resetEmail');
  }

  clearResetParams() {
    sessionStorage.removeItem('resetKey');
    sessionStorage.removeItem('resetEmail');
  }
}

export default new SessionService();