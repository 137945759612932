<template>
  <div class="shares__table">
    <div class="shares__table-header">
      <h2 class="shares__table-title">
        {{ $t("dashboard.sharesTable.title") }}
      </h2>
    </div>
    <div class="shares__table-wrapper">
      <div v-if="race.sharedAlbums.length === 0" class="shares__table-empty">
        {{ $t("dashboard.sharesTable.noData") }}
      </div>
      <q-table
        v-else
        v-model:pagination="pagination"
        row-key="orderId"
        :rows="race.sharedAlbums"
        :columns="sharesColumns"
        :rows-per-page-options="[5, 10, 15]"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td v-for="col in sharesColumns" :key="col.name" :props="props">
              <q-btn
                v-if="col.name === 'copyBtn'"
                @click="copyToClipboard(props.row.link)"
              >
                <q-icon name="content_copy" size="16px" class="q-mr-xs" />
              </q-btn>
              <div v-if="col.name === 'shareLink'">
                <a
                  :href="props.row.link"
                  target="_blank"
                  class="shares__table-link text-primary"
                >
                  {{ props.row.link }}
                </a>
              </div>
              <div v-else>
                {{ props.row[col.field] }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
      <q-pagination
        v-if="race.sharedAlbums.length > 0"
        v-model="pagination.page"
        :max="pagesNumber"
        :boundary-links="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SharesTable",
  props: {
    race: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        sortBy: "shareId",
        descending: false,
        page: 1,
        rowsPerPage: 10,
      },
    };
  },
  computed: {
    sharesColumns() {
      return [
        {
          name: "shareId",
          label: this.$t("dashboard.sharesTable.shareId"),
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "title",
          label: this.$t("dashboard.sharesTable.title"),
          align: "center",
          field: "title",
          sortable: true,
        },
        {
          name: "shareLink",
          label: this.$t("dashboard.sharesTable.link"),
          align: "center",
          field: "link",
          sortable: true,
        },
        {
          name: "copyBtn",
          label: this.$t("dashboard.sharesTable.copy"),
          align: "left",
          field: "copyBtn",
        },
      ];
    },
    pagesNumber() {
      return Math.ceil(
        this.race.sharedAlbums.length / this.pagination.rowsPerPage
      );
    },
  },
  methods: {
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$q.notify({
          color: "positive",
          message: this.$t("dashboard.sharesTable.linkCopySuccess"),
          position: "bottom",
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          message: this.$t("dashboard.sharesTable.linkCopyFail"),
          position: "bottom",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shares__table {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e9ecef;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $secondary;
    margin: 0;
  }

  &-link {
    text-decoration: none;
  }

  &-wrapper {
    padding-bottom: 8px;
  }

  &-empty {
    text-align: center;
    padding: 20px;
    color: #6c757d;
    font-size: 1rem;
  }
}

.q-table {
  th {
    font-weight: 600;
    background-color: #f1f3f5;
    color: #495057;
    font-size: 0.8rem;
    padding: 12px 12px;
    border-bottom: 2px solid #dee2e6;

    &.text-left {
      text-align: left;
    }

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }

    &:hover {
      background-color: #e9ecef;
    }
  }

  tbody td {
    padding: 12px 8px;
    font-size: 0.7rem;
    color: #212529;
  }

  tbody tr:hover {
    background-color: #f8f9fa;
  }
}

.q-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: $breakpoint-xs-max) {
  .shares__table-header {
    flex-direction: column;
  }

  .shares__table-title {
    font-size: 1.3rem;
  }

  .summary-label {
    font-size: 0.9rem;
  }
}
</style>
