<template>
  <div class="orders__table">
    <div class="orders__table-header">
      <h2 class="orders__table-title">
        {{ $t("dashboard.ordersTable.title") }}
      </h2>
      <div class="orders__table-summary">
        <span class="summary-label"
          >{{ $t("dashboard.ordersTable.sumCommissions") }}:&nbsp;</span
        >
        <span class="summary-value">{{ totalCommissionEarned }}zł</span>
      </div>
    </div>
    <div class="orders__table-wrapper">
      <q-table
        v-model:pagination="pagination"
        row-key="orderId"
        :rows="race.orders"
        :columns="ordersColumns"
        :rows-per-page-options="[10, 20, 50]"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td v-for="col in ordersColumns" :key="col.name" :props="props">
              <div v-if="col.name === 'linkOrderItem'">
                <q-icon name="link" size="16px" class="q-mr-xs" />
                <a
                  :href="props.row.orderItemLink"
                  target="_blank"
                  class="orders__table-link text-primary"
                >
                  {{ props.row.orderItemName }}
                </a>
              </div>
              <div v-else>
                {{ props.row[col.field] }}
              </div>
            </q-td>
          </q-tr>
        </template>
      </q-table>
      <q-pagination
        v-model="pagination.page"
        :max="pagesNumber"
        :direction-links="true"
        :boundary-links="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersTable",
  props: {
    totalCommissionEarned: {
      type: Number,
      required: true,
    },
    race: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        sortBy: "orderId",
        descending: false,
        page: 1,
        rowsPerPage: 10,
      },
    };
  },
  computed: {
    ordersColumns() {
      return [
        {
          name: "orderId",
          label: this.$t("dashboard.ordersTable.orderId"),
          align: "left",
          field: "orderId",
          sortable: true,
          headerClass: "text-left",
        },
        {
          name: "linkOrderItem",
          label: this.$t("dashboard.ordersTable.linkOrderItem"),
          align: "left",
          field: "orderItemName",
          sortable: true,
          headerClass: "text-left",
        },
        {
          name: "orderDate",
          label: this.$t("dashboard.ordersTable.orderDate"),
          align: "center",
          field: "orderDate",
          sortable: true,
          headerClass: "text-left",
        },
        {
          name: "orderAmount",
          label: this.$t("dashboard.ordersTable.orderAmount"),
          align: "center",
          field: "orderAmount",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "coupon",
          label: this.$t("dashboard.ordersTable.coupon"),
          align: "center",
          field: "coupon",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "currency",
          label: this.$t("dashboard.ordersTable.currency"),
          align: "center",
          field: "currency",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "authorCommission",
          label: this.$t("dashboard.ordersTable.authorCommission"),
          align: "center",
          field: "authorCommission",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "paygateCommision",
          label: this.$t("dashboard.ordersTable.paygateCommission"),
          align: "center",
          field: "paygateCommission",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "runpixieCommision",
          label: this.$t("dashboard.ordersTable.runpixieCommission"),
          align: "center",
          field: "runpixieCommission",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "afterFeesTotal",
          label: this.$t("dashboard.ordersTable.afterFeesTotal"),
          align: "right",
          field: "afterFeesTotal",
          sortable: true,
          headerClass: "text-right",
        },
      ];
    },
    pagesNumber() {
      return Math.ceil(this.race.orders.length / this.pagination.rowsPerPage);
    },
  },
};
</script>

<style lang="scss">
.orders__table {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &-wrapper {
    padding: 0 0 8px 0;
  }

  &-link {
    text-decoration: none;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e9ecef;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $secondary;
    margin: 0;
  }

  &-summary {
    display: flex;

    .summary-label {
      font-size: 1rem;
      color: $secondary;
      margin-right: 8px;
    }

    .summary-value {
      font-size: 1.1rem;
      font-weight: 600;
      color: $positive;
    }
  }
}

.q-table {
  th {
    font-weight: 600;
    background-color: #f1f3f5;
    color: #495057;
    font-size: 0.8rem;
    padding: 12px 0px 12px 12px;
    border-bottom: 2px solid #dee2e6;

    &.text-left {
      text-align: left;
    }

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }

    &:hover {
      background-color: #e9ecef;
    }
  }

  &__card {
    box-shadow: none;
  }

  tbody td {
    padding: 12px 8px;
    font-size: 0.7rem;
    color: #212529;
  }

  tbody tr:hover {
    background-color: #f8f9fa;
  }
}

.q-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: $breakpoint-xs-max) {
  .orders__table {
    &-header {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    &-title {
      font-size: 1.3rem;
    }
    .summary-label {
      font-size: 0.9rem;
    }
  }
}
</style>