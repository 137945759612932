<template>
  <q-uploader
    ref="uploader"
    url="https://runpixie.com"
    :label="$t('addPhoto.title')"
    multiple
    color="white"
    flat
    :no-thumbnails="!filePickerThumbs"
    accept=".jpg, .heic, image/*"
    text-color="primary"
    class="file-picker file-wrapper"
    :filter="sortByName"
    @added="added"
    @removed="removed"
  />
</template>

<script>
import _ from "lodash";
export default {
  name: "FilePicker",
  data() {
    return {
      selectedImages: [],
    };
  },
  props: {
    filePickerThumbs: {
      required: true,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    removed(file) {
      const key = file[0].__key;
      if (this.selectedImages.length) {
        _.remove(this.selectedImages, function (el) {
          return el.__key === key;
        });
        this.$emit("setImages", this.selectedImages);
      }
    },
    added(files) {
      files.forEach((file) => {
        this.selectedImages.push(file);
      });
      this.$emit("setImages", this.selectedImages);
    },
    async addFromParent(selectedImagesCopy) {
      this.selectedImages = [];
      await this.$refs.uploader.reset();
      await this.$refs.uploader.addFiles(selectedImagesCopy);
    },
    sortByName(files) {
      let arr = files.sort(function (a, b) {
        return b.name.localeCompare(a.name, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });

      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-picker {
  width: 100%;
  max-width: 460px;
  height: auto;
  max-height: 350px;
}
</style>
