import api from '@/api/api';

export const dashboardRaces = {
  namespaced: true,
  state: {
    raceId: null,
    loading: false,
    isSidebarOpen: false,
    currentRace: JSON.parse(localStorage.getItem('currentRace')) || null,
    races: JSON.parse(localStorage.getItem('races')) || [],
    links: [
      { icon: 'share', text: 'dashboard.menu.shares' },
      { icon: 'shopping_cart', text: 'dashboard.main.totalOrders' },
    ],
  },
  getters: {
    getCurrentRace: (state) => {
      if (!state.currentRace && state.raceId) {
        return (
          state.races.find((race) => race.id === parseInt(state.raceId)) || null
        );
      }
      return state.currentRace;
    },
    isSidebarOpen: (state) => state.isSidebarOpen,
  },
  actions: {
    async fetchUserRaces({ commit, rootState }) {
      try {
        const userId = rootState.auth.user.id;
        if (!userId) {
          throw new Error('User not logged in');
        }

        const races = await api.getUserRaces(userId);
        commit('setRaces', races);
      } catch (error) {
        commit('setRaces', []);
        throw error;
      }
    },
    async fetchRaceById({ commit, state }, raceId) {
      commit('setLoading', true);
      try {
        const currentRace = await api.getRaceData(raceId);
        const race = state.races.find((race) => race.id === parseInt(raceId));
        const combinedRaceData = {
          ...race,
          ...currentRace,
        };

        commit('setCurrentRace', combinedRaceData);
        return combinedRaceData
      } catch (error) {
        commit('setCurrentRace', {});
      } finally {
        commit('setLoading', false);
      }
    },
    toggleSidebar({ commit }, isOpen) {
      commit('setSidebar', isOpen);
    },
  },
  mutations: {
    setRaces(state, races) {
      state.races = races || [];
      localStorage.setItem('races', JSON.stringify(state.races));
    },
    setCurrentRace(state, currentRace) {
      state.currentRace = currentRace;
      localStorage.setItem('currentRace', JSON.stringify(currentRace));
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSidebar(state, isOpen) {
      state.isSidebarOpen = isOpen;
    }
  },
};
