<template>
  <q-page class="window-width row justify-center items-center recover-page">
    <div class="column">
      <div class="row">
        <q-card square class="recover-page__card">
          <q-card-section class="bg-primary">
            <h4 class="text-h5 text-white q-my-md">
              {{ $t("recoverPassword.title") }}
            </h4>
          </q-card-section>
          <div class="q-mx-lg q-mt-lg q-mb-sm recover-page__info">
            <div>
              <q-icon name="info" color="info" size="md" />
              <span class="text-info recover-page__info-text q-ml-xs">{{
                $t("recoverPassword.resetInfo")
              }}</span>
            </div>
            <ul class="recover-page__info-requirements q-mb-none q-mx-lg">
              <li>{{ $t("recoverPassword.requirements1") }}</li>
              <li>{{ $t("recoverPassword.requirements2") }}</li>
              <li>{{ $t("recoverPassword.requirements3") }}</li>
              <li>{{ $t("recoverPassword.requirements4") }}</li>
            </ul>
          </div>
          <q-card-section>
            <q-form @submit.prevent="handleResetPassword">
              <q-input
                ref="newPassword"
                square
                clearable
                v-model="newPassword"
                :type="passwordFieldType"
                lazy-rules
                :rules="[required, validatePasswordStrength]"
                :label="$t('login.password')"
              >
                <template v-slot:prepend>
                  <q-icon name="lock" />
                </template>
                <template v-slot:append>
                  <q-icon
                    :name="visibilityIcon"
                    @click="switchVisibility"
                    class="cursor-pointer"
                  />
                </template>
              </q-input>
              <q-input
                ref="repeatPassword"
                square
                clearable
                v-model="repeatPassword"
                :type="passwordFieldType"
                lazy-rules
                :rules="[required, matchPasswords]"
                :label="$t('recoverPassword.repeat')"
              >
                <template v-slot:prepend>
                  <q-icon name="lock" />
                </template>
                <template v-slot:append>
                  <q-icon
                    :name="visibilityIcon"
                    @click="switchVisibility"
                    class="cursor-pointer"
                  />
                </template>
              </q-input>
              <q-card-actions class="q-px-lg">
                <q-btn
                  unelevated
                  size="lg"
                  color="secondary"
                  type="submit"
                  class="full-width text-white"
                  :label="$t('recoverPassword.resetButton')"
                />
              </q-card-actions>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapActions } from "vuex";
import SessionService from "@/services/session.service.js";

export default {
  name: "PasswordRecoveryPage",
  data() {
    return {
      newPassword: "",
      repeatPassword: "",
      passwordFieldType: "password",
      visibility: false,
    };
  },
  computed: {
    visibilityIcon() {
      return this.passwordFieldType === "password"
        ? "visibility_off"
        : "visibility";
    },
  },
  methods: {
    switchVisibility() {
      this.visibility = !this.visibility;
      this.passwordFieldType = this.visibility ? "text" : "password";
    },
    ...mapActions("auth", ["resetPassword"]),
    
    matchPasswords(value) {
      return value === this.newPassword || this.$t("validation.samePassword");
    },
    required(value) {
      return (value && value.length > 0) || this.$t("validation.required");
    },
    async handleResetPassword() {
      const passwordStrengthValidation = this.validatePasswordStrength(
        this.newPassword
      );

      if (passwordStrengthValidation !== true) {
        this.notifyError(passwordStrengthValidation);
        return;
      }

      const key = SessionService.getResetKey();
      const email = SessionService.getResetEmail();

      this.$q.loading.show();

      try {
        await this.resetPassword({
          email,
          key,
          newPassword: this.newPassword,
          newPasswordConfirmation: this.repeatPassword,
        });

        this.$q.notify({
          icon: "done",
          color: "positive",
          message: this.$t("recoverPassword.resetSuccess"),
        });

        SessionService.clearResetParams();

        this.$router.push("/login");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },

    handleError(error) {
      const { response } = error;
      const message =
        (response && response.data && response.data.message) ||
        error.message ||
        error.toString();

      this.notifyError(message);
    },

    notifyError(message) {
      this.$q.notify({
        icon: "error",
        color: "negative",
        message: this.$t(message),
      });
    },

    validatePasswordStrength(password) {
      if (
        password.length < 8 ||
        !/[0-9]/.test(password) ||
        !/[A-Z]/.test(password) ||
        !/[a-z]/.test(password)
      ) {
        return this.$t("recoverPassword.validation");
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.recover-page {
  background: linear-gradient(lighten($primary, 12%), $primary);

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-text {
      text-wrap: balance;
      font-weight: 600;
      line-height: 1.1;
    }

    &-requirements {
      display: grid;
      justify-content: space-around;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem 2rem;
      padding-left: 2rem;

      li {
        font-weight: 500;
        color: $secondary;
      }
    }
  }

  &__card {
    margin-top: 150px;
    width: 400px;
  }
}

@media (max-width: 450px) {
  .recover-page {
    &__card {
      margin-top: 150px;
      width: 100vw;
      height: 100vh;
    }

    &__info-requirements {
      padding: 0;
      margin: 1rem 0;
    }
  }
}
</style>
