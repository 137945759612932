export const validationMethods = {
  required(val, t) {
    return (val && val.length > 0) || t('validation.required');
  },
  short(val, t) {
    return (val && val.length > 2) || t('validation.minValue');
  },
  isEmail(val, t) {
    const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(val) || t('validation.email');
  },
  isPhoneNumber(val, t) {
    const phonePattern = /^\+?[\d -]{9,}$/;
    if (!val) {
      return true;
    }
    return phonePattern.test(val) || t('validation.phoneNumber');
  },
  isPortfolioUrl(val, t) {
    const portfolioPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return (val && portfolioPattern.test(val)) || t('validation.portfolio');
  },
  countryFieldRequired(val) {
    return val && val.value ? true : this.$t('validation.required');
  },
};